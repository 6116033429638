import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  FBIcon,
  Footer,
  IGIcon,
  Layout,
  TGIcon,
  MainTitle,
  MetaHeader,
  ShopeeIcon,
  Breadcrumb,
} from '@components'

const title = 'Katalog & Ebooks Percuma'
const subtitle = 'Muat turun Katalog & Ebook percuma'

export default function katalogEbooks({ data }: any) {
  const {
    allFile: { group },
    site: {
      siteMetadata: {
        social: {
          buku: { fb, ig, shopee, tg },
        },
      },
    },
  } = data

  const transformNewNamed = group
    .filter(({ slug }: any) => slug) // remove main.png in sabun
    .map(({ slug, ...rest }: any) => ({
      ...rest,
      slug: (slug || '').replace(/_/g, '-'),
      title: (slug || '').replace(/_/g, ' '),
    }))

  return (
    <Layout>
      <MetaHeader title={title} description={subtitle} />
      <div className='min-h-screen flex items-center justify-center'>
        <div className='flex-1 max-w-4xl mx-auto p-2 md:p-10'>
          <Breadcrumb />

          <MainTitle title={title} subtitle={subtitle} />

          <ul className='max-w-xs mx-auto sm:max-w-none grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            {transformNewNamed.map((item: { id: string } | any, i: number) => (
              <Boxes key={i} item={item} />
            ))}
          </ul>
        </div>
      </div>

      <Footer>
        <li>
          <IGIcon ig={ig} />
        </li>
        <li>
          <FBIcon fb={fb} />
        </li>
        <li>
          <ShopeeIcon shopee={shopee} />
        </li>
        <li>
          <TGIcon tg={tg} />
        </li>
      </Footer>
    </Layout>
  )
}

const Boxes = ({ item }: any) => {
  const {
    slug,
    title,
    nodes: [img],
  } = item

  return (
    <li className='bg-white rounded shadow-md hover:shadow-lg border relative'>
      <Link
        to={`/katalog-ebooks/${slug}`}
        className='flex flex-col h-full cursor-pointer'>
        <GatsbyImage
          alt={title}
          imgStyle={{ objectFit: 'contain' }}
          image={img.childImageSharp.gatsbyImageData}
          style={{ height: '11em', width: 'auto' }}
        />
        <div className='pt-0 sm:pt-2 px-5 pb-5 flex items-center flex-grow justify-center'>
          <span className='text-sm text-center capitalize'>{title}</span>
        </div>
      </Link>
      <div className='absolute top-0 right-0 mt-1 mr-2 cursor-pointer'>
        <svg style={{ width: '25px', height: '25px' }} viewBox='0 0 24 24'>
          <title>Download file {title}</title>
          <path
            fill='#938D7A'
            d='M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z'
          />
        </svg>
      </div>
    </li>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        social {
          buku {
            tg
            fb
            ig
            shopee
          }
        }
      }
    }
    allFile(
      filter: { dir: { regex: "/katalog-ebooks/" }, name: { regex: "/main/" } }
    ) {
      group(field: relativeDirectory) {
        slug: fieldValue
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
